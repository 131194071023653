<template>
  <div>
    <!--loading-->
    <vs-progress indeterminate color="primary" :height="1" :class="{ 'invisible': !isLoading }"/>

    <div>
      <vs-divider>Harga Unit</vs-divider>
      <div class="vx-row mb-3">
        <div class="vx-col sm:w-2/12 w-full">
          <label class="ml-1 text-xs">HPP Tanah</label>
          <v-money class="w-full" v-model="data.unit.hpp_tanah" readonly/>
        </div>
        <div class="vx-col sm:w-2/12 w-full">
          <label class="ml-1 text-xs">HPP Bangunan</label>
          <v-money class="w-full" v-model="data.unit.hpp_bangunan" readonly/>
        </div>
        <div class="vx-col sm:w-4/12 w-full">
          <label class="ml-1 text-xs">Total HPP</label>
          <v-money class="w-full" :value="(data.unit.hpp_tanah + data.unit.hpp_bangunan)" readonly/>
        </div>
        <div class="vx-col sm:w-4/12 w-full">
          <label class="ml-1 text-xs">Harga Standar *</label>
          <v-money class="w-full" v-model="data.unit.harga_standar" readonly/>
        </div>
      </div>
      <div class="vx-row mb-3">
        <div class="vx-col sm:w-1/6 w-full">
          <label class="ml-1 text-xs">Biaya Kel. Luas</label>
          <v-money class="w-full" v-model="data.unit.harga_luas" readonly/>
        </div>
        <div class="vx-col sm:w-1/6 w-full">
          <label class="ml-1 text-xs">Biaya Kel. Tinggi</label>
          <v-money class="w-full" v-model="data.unit.harga_tinggi" readonly/>
        </div>
        <div class="vx-col sm:w-1/6 w-full">
          <label class="ml-1 text-xs">Biaya View</label>
          <v-money class="w-full" v-model="data.unit.harga_view" readonly/>
        </div>
        <div class="vx-col sm:w-1/6 w-full">
          <label class="ml-1 text-xs">Biaya Mutu</label>
          <v-money class="w-full" v-model="data.unit.harga_mutu" readonly/>
        </div>
        <div class="vx-col sm:w-1/6 w-full">
          <label class="ml-1 text-xs">Biaya Adm</label>
          <v-money class="w-full" v-model="data.unit.biaya_adm" readonly/>
        </div>
        <div class="vx-col sm:w-1/6 w-full">
          <label class="ml-1 text-xs">Biaya Peningkatan Hak</label>
          <v-money class="w-full" v-model="data.unit.biaya_notaris" readonly/>
        </div>
      </div>
      <div class="vx-row mb-3">
        <div class="vx-col sm:w-6/12 w-full">
          <label class="ml-1 text-xs">DPP *</label>
          <v-money class="w-full" v-model="data.unit.dpp" readonly/>
        </div>
        <div class="vx-col sm:w-6/12 w-full">
          <label class="ml-1 text-xs">Total Harga Unit</label>
          <v-money class="w-full" v-model="data.unit.total_harga_unit" readonly/>
        </div>
      </div>

      <vs-divider class="mt-base">Pembayaran</vs-divider>
      <div class="vx-row mb-3">
        <div class="vx-col sm:w-4/12 w-full">
          <label class="ml-1 text-xs">Rencana Bayar</label>
          <vs-input class="w-full" type="text" v-model="data.nama_cara_bayar" readonly/>
        </div>
        <div class="vx-col sm:w-4/12 w-full">
          <label class="ml-1 text-xs">Bank</label>
          <vs-input class="w-full" type="text" v-model="data.nama_bank_kpr" readonly/>
        </div>
        <div class="vx-col sm:w-4/12 w-full -mt-1.5">
          <label class="ml-1 text-xs opacity-75 -mt-1">COA Debet</label>
          <vs-input class="w-full" type="text" v-model="data.nama_coa_debet" readonly/>
        </div>
      </div>
      <div class="vx-row mb-3">
        <div class="vx-col sm:w-3/12 w-full">
          <label class="ml-1 text-xs">Diskon</label>
          <vs-input class="w-full" :value="`${idr(data.diskon_jml)} (${data.diskon_persen}%)`" readonly/>
        </div>
        <div class="vx-col sm:w-3/12 w-full">
          <label class="ml-1 text-xs">PPN</label>
          <vs-input class="w-full" :value="`${idr(data.ppn_jml)} (${data.ppn_persen}%)`" readonly/>
        </div>
        <div class="vx-col sm:w-3/12 w-full">
          <label class="ml-1 text-xs">PPh Jenis</label>
          <vs-input class="w-full" type="text" v-model="data.pph_nama" readonly/>
        </div>
        <div class="vx-col sm:w-3/12 w-full">
          <label class="ml-1 text-xs">PPh</label>
          <vs-input class="w-full" :value="`${idr(data.pph_jml)} (${data.pph_persen}%)`" readonly/>
        </div>
      </div>
      <div class="vx-row my-6">
        <div class="vx-col sm:w-12/12 w-full">
          <h5 class="text-warning float-right">
            <span class="text-xs">Total Harga Jual</span>
            <strong> = {{ data.total_harga_jual | idr }}</strong>
          </h5>
        </div>
      </div>

      <vs-divider class="mt-base">Booking Fee</vs-divider>
      <div class="vx-row mb-3">
        <div class="vx-col sm:w-3/12 w-full">
          <label class="ml-1 text-xs">Cara Bayar Booking Fee *</label>
          <vs-input class="w-full" type="text" v-model="data.nama_cara_bayar_bf" readonly/>
        </div>
        <div class="vx-col sm:w-3/12 w-full"><label class="ml-1 text-xs">Tgl. Jatuh Tempo *</label>
          <vs-input class="w-full" :value="data.booking_fee_due" readonly/>
        </div>
        <div class="vx-col sm:w-3/12 w-full">
          <label class="ml-1 text-xs">Booking Fee Status</label>
          <vs-input class="w-full" :value="data.booking_fee_status === 'INCLUDE' ? 'Include di Harga Jual' : 'Exclude di Harga Jual'" readonly/>
        </div>
        <div class="vx-col sm:w-3/12 w-full">
          <label class="ml-1 text-xs">Total Booking Fee</label>
          <v-money class="w-full" v-model="data.booking_fee" readonly/>
        </div>
      </div>
      <div class="vx-row my-6"></div>

      <vs-divider class="mt-base">Uang Muka</vs-divider>
      <div class="vx-row mb-3">
        <div class="vx-col sm:w-6/12 w-full">
          <label class="ml-1 text-xs">Total Uang Muka</label>
          <vs-input class="w-full" :value="`${idr(data.dp_jml)} (${data.dp_persen}%)`" readonly/>
        </div>
        <div class="vx-col sm:w-6/12 w-full">
          <label class="ml-1 text-xs">Termin Uang Muka ( X )</label>
          <vs-input class="w-full" :value="data.termin_dp" readonly/>
        </div>
      </div>
      <div class="vx-row my-6">
        <div class="vx-col sm:w-12/12 w-full">
          <h5 class="text-warning float-right">
            <span class="text-xs">Sisa Uang Muka</span>
            <strong> = {{ data.dp_jml | idr }}</strong>
          </h5>
        </div>
      </div>

      <vs-divider class="mt-base">Angsuran</vs-divider>
      <div class="vx-row mb-3">
        <div class="vx-col sm:w-4/12 w-full">
          <label class="ml-1 text-xs">Sisa Angsuran</label>
          <v-money class="w-full" :value="data.angsuran_jml" disabled/>
        </div>
        <div class="vx-col sm:w-4/12 w-full">
          <label class="ml-1 text-xs">Termin Angsuran ( X ) *</label>
          <vs-input class="w-full" :value="data.termin_angsuran" readonly/>
        </div>
        <div class="vx-col sm:w-4/12 w-full">
          <label class="ml-1 text-xs">Tgl. Mulai Jatuh Tempo *</label>
          <vs-input class="w-full" :value="data.angsuran_start_due" readonly/>
        </div>
      </div>
      <div class="vx-row my-6">
        <div class="vx-col sm:w-12/12 w-full">
          <h5 class="text-warning float-right">
            <span class="text-xs">Sisa Angsuran</span>
            <strong> = {{ data.angsuran_jml | idr }}</strong>
          </h5>
        </div>
      </div>
      <div class="vx-row my-6">
        <div class="vx-col sm:w-12/12 w-full">
          <h5 class="text-warning float-right font-bold">
            Grand Total = {{ data.grand_total | idr }}
          </h5>
        </div>
      </div>

      <vs-divider class="mt-base">Review Termin</vs-divider>
      <vs-table :data="bookingAngsurans" stripe class="fix-table-input-background">
        <template slot="thead" class="text-sm">
          <vs-th class="whitespace-no-wrap">#</vs-th>
          <vs-th class="whitespace-no-wrap">Termin Ke</vs-th>
          <vs-th class="whitespace-no-wrap">Tipe</vs-th>
          <vs-th class="whitespace-no-wrap text-right">Persen Bayar</vs-th>
          <vs-th class="whitespace-no-wrap text-right">Jumlah</vs-th>
          <vs-th class="whitespace-no-wrap text-right">DPP</vs-th>
          <vs-th class="whitespace-no-wrap text-right">PPN</vs-th>
          <vs-th class="whitespace-no-wrap text-right">PPh</vs-th>
          <vs-th class="whitespace-no-wrap text-right">Terbayar</vs-th>
          <vs-th class="whitespace-no-wrap">Due Date</vs-th>
          <vs-th class="whitespace-no-wrap text-right">Keterangan</vs-th>
        </template>
        <template slot-scope="{data}">
          <vs-tr v-for="(item, index) in data" :key="index" class="text-sm">
            <vs-td>{{ index + 1 }}</vs-td>
            <vs-td><span class="font-bold" :class="['text-' + getColorOfTipeAngsuran(item.tipe_angsuran)]">Ke - {{ item.no_termin }}</span></vs-td>
            <vs-td>
              <span class="inline-flex items-center whitespace-no-wrap justify-center p-2 text-xs leading-none text-white rounded-full" :class="['bg-' + getColorOfTipeAngsuran(item.tipe_angsuran)]">
                {{ item.tipe_angsuran }}
              </span>
            </vs-td>
            <vs-td class="text-right">{{ item.persen | idr(2) }}%</vs-td>
            <vs-td class="text-right">{{ item.jml_nominal | idr }}</vs-td>
            <vs-td class="text-right">{{ item.dpp | idr }}</vs-td>
            <vs-td class="text-right">{{ item.jml_ppn | idr }}</vs-td>
            <vs-td class="text-right">{{ item.jml_pph | idr }}</vs-td>
            <vs-td class="text-right">{{ item.jml_bayar | idr }}</vs-td>
            <vs-td class="text-center whitespace-no-wrap">{{ item.tgl_due }}</vs-td>
            <vs-td>{{ item.keterangan }}</vs-td>
          </vs-tr>
          <!--footer-->
          <vs-tr v-if="bookingAngsurans.length > 0" class="text-sm">
            <vs-td class="font-bold">Total</vs-td>
            <vs-td class="text-right font-bold" colspan="3" >{{ footerTermin.totalPercent | idr(2) }} %</vs-td>
            <vs-td class="text-right font-bold">{{ footerTermin.totalNominal | idr }}</vs-td>
            <vs-td class="text-right font-bold">{{ footerTermin.totalDpp | idr }}</vs-td>
            <vs-td class="text-right font-bold">{{ footerTermin.totalPpn | idr }}</vs-td>
            <vs-td class="text-right font-bold">{{ footerTermin.totalPph | idr }}</vs-td>
            <vs-td class="text-right font-bold">{{ footerTermin.totalBayar | idr }}</vs-td>
          </vs-tr>
        </template>
      </vs-table>
    </div>

  </div>
</template>

<script>
import BookingRepository from '@/repositories/marketing/booking-repository'
import VMoney from '@/views/components/v-money/VMoney'
import KontrakAngsuranRepository from '@/repositories/marketing/kontrak/kontrak-angsuran-repository'
import constant from '@/constant/constant'
import _ from 'lodash'

export default {
  name: 'TabAngsuran',
  props: ['isActive'],
  components: {
    VMoney
  },
  watch: {
    isActive (value) {
      if (value && !this.isDataInited) {
        this.initData()
      }
    }
  },
  computed: {
    footerTermin () {
      return {
        totalPercent: _.sumBy(this.bookingAngsurans, item => item.persen),
        totalNominal: _.sumBy(this.bookingAngsurans, item => item.jml_nominal),
        totalDpp: _.sumBy(this.bookingAngsurans, item => item.dpp),
        totalPpn: _.sumBy(this.bookingAngsurans, item => item.jml_ppn),
        totalPph: _.sumBy(this.bookingAngsurans, item => item.jml_pph),
        totalBayar: _.sumBy(this.bookingAngsurans, item => item.jml_bayar)
      }
    }
  },
  data () {
    return {
      isDataInited: false,
      isLoading: false,
      bookingAngsurans: [],
      data: {
        unit: {}
      }
    }
  },
  methods: {
    initData () {
      this.getBooking()
      this.isDataInited = true
    },

    getBooking () {
      this.isLoading = true

      const idBooking = this.$route.params.idBooking
      const params = { withUnitInformation: true }
      BookingRepository.show(idBooking, params)
        .then(response => {
          this.data = _.cloneDeep(response.data.data)
          // create unit object
          const keysFromBooking = _.pick(this.data, ['harga_standar', 'harga_ajb', 'harga_luas', 'harga_tinggi', 'harga_view', 'harga_mutu', 'harga_ppn', 'biaya_adm', 'biaya_notaris', 'dpp', 'biaya_bphtb', 'biaya_lain1', 'biaya_lain2', 'biaya_lain3', 'total_harga_unit'])
          this.data.unit = { ...this.data.properti_unit, ...keysFromBooking }
          this.getBookingAngsuran(idBooking)
        })
        .catch(error => {
          if (error.response.status === 404) {
            this.$router.push({ name: '404' })
          } else {
            console.log(error)
            this.notifyError('Terjadi kesalahan.')
          }
        })
        .finally(() => {
          this.isLoading = false
        })
    },

    getBookingAngsuran (idBooking) {
      KontrakAngsuranRepository.get(idBooking)
        .then(response => {
          this.bookingAngsurans = response.data.data
        })
        .catch(error => {
          console.log(error)
          this.notifyError('Terjadi kesalahan.')
        })
    },

    getColorOfTipeAngsuran (tipe) {
      if (tipe === constant.TIPE_ANGSURAN.BOOKING_FEE) {
        return 'danger'
      } else if (tipe === constant.TIPE_ANGSURAN.UANG_MUKA) {
        return 'green'
      } else if (tipe === constant.TIPE_ANGSURAN.ANGSURAN) {
        return 'primary'
      } else {
        return 'black'
      }
    },

    idr (value, decimal = 2) {
      return this.$options.filters.idr(value, decimal)
    }
  }
}
</script>
